var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  'interactions-receive-entrance': true,
  'interactions-receive-entrance__mobile': _vm.isMobile,
}},[_c('AnnouncementEntrance'),_c('section',{class:{
    'bottom-wrapper': true,
    'bottom-wrapper__mobile': _vm.isMobile,
  }},[_c('LotteryEntrance'),_c('CheckInEntrance'),_c('AnswerCardEntrance'),_c('QuestionnaireEntrance')],1),_c('section',{class:{
    'aside-right-wrapper': true,
    'aside-right-wrapper__mobile': _vm.isMobile,
  }},[_c('RedEnvelopeEntrance',{staticClass:"red-envelope-entrance"}),_c('PushCardEntrance',{staticClass:"push-card-entrance"}),_c('WelfareLotteryEntrance',{staticClass:"welfare-lottery-entrance"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }