var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  'plv-watch__likes-wrapper': true,
  'plv-watch__likes-wrapper__mobile': _vm.isMobile,
}},[_c('div',{class:{
    'plv-watch__likes': true,
    'plv-watch__likes__mobile': _vm.isMobile,
  }},[_c('span',{staticClass:"plv-watch__likes-icon",on:{"click":_vm.debounceLikeCick}}),_c('p',{staticClass:"plv-watch__likes-num"},[_vm._v(" "+_vm._s(_vm.likeNum)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }