var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{class:{
    'plv-watch-pc':true,
    'plv-watch-pc--alone': _vm.isAloneChannelScene
  }},[_c('div',{ref:"plv-pc-top",staticClass:"plv-watch-pc__top",class:{
           'plv-watch-pc__top--fullscreen':_vm.playerCtrl.isFullScreen
         },attrs:{"id":"plv-pc-top"}},[_c('div',{class:{
        'plv-watch-pc__screen':true,
        'plv-watch-pc__screen-main':_vm.isPPTMainPosition,
        'plv-watch-pc__screen-sub':_vm.isPlayerMainPosition
      }},[_c('div',{staticClass:"plv-watch-pc__screen__height"},[_c('div',{ref:"plv-pc-main",staticClass:"plv-watch-pc__screen__inner",attrs:{"id":"plv-pc-main"}}),(_vm.isAloneChannelScene)?_c('div',{staticClass:"plv-watch-pc__screen__inner",staticStyle:{"display":"none"},attrs:{"id":"plv-pc-master-rtc-player"}}):_vm._e()])]),_c('div',{class:{
        'plv-watch-pc__screen':true,
        'plv-watch-pc__screen-main':_vm.isPlayerMainPosition,
        'plv-watch-pc__screen-sub':_vm.isPPTMainPosition
      }},[_c('div',{staticClass:"plv-watch-pc__screen__height"},[_c('div',{ref:"plv-pc-side",staticClass:"plv-watch-pc__screen__inner",attrs:{"id":"plv-pc-side"}}),(!_vm.isAloneChannelScene)?_c('div',{staticClass:"plv-watch-pc__screen__inner",staticStyle:{"display":"none"},attrs:{"id":"plv-pc-master-rtc-player"}}):_vm._e()])]),_c('div',{staticClass:"plv-watch-pc__side"},[_c('div',{staticClass:"plv-watch-pc__chat plv-skin--dark"},[(_vm.playerInited)?_c('tab-nav',{staticClass:"tab-nav",attrs:{"tabData":_vm.tabData,"originTabTypes":_vm.originTabTypes},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}}):_vm._e(),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCustomAcitveTab()),expression:"isCustomAcitveTab()"}],staticClass:"custom-tab-content-wrapper"},[(_vm.enableRenderIRComponent)?_c('pc-product-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowProductList),expression:"isShowProductList"}],on:{"change-switch":_vm.changeProductSwitch}}):_vm._e(),(_vm.playerInited)?_c('pc-rtc-panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowPcRtcPanel),expression:"isShowPcRtcPanel"}],on:{"open":function($event){return _vm.handleRTCTab(true)},"close":function($event){return _vm.handleRTCTab(false)}}}):_vm._e()],1),_c('section',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCustomAcitveTab()),expression:"!isCustomAcitveTab()"}],ref:"plv-pc-origin-tab-content",staticClass:"plv-pc-origin-tab-content"},[(_vm.playerInited && _vm.isEnableDonate)?_c('pc-donate-panel',{attrs:{"donateConfig":_vm.donateConfig}}):_vm._e()],1),_c('section',{staticClass:"bubble-wrapper"},[(_vm.playerInited)?_c('product-bubble'):_vm._e(),_c('donate-bubble',{attrs:{"enable":_vm.isActiveChatTab}})],1)],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }